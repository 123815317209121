<template>
  <div class="app-container">
    <el-dialog
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      width="60%"
    >
      <!-- 查询区域 -->
      <div>
        <el-form layout="inline">
          <el-row :gutter="24">
            <el-col :md="6" :sm="8">
              <el-button @click="selectDataListButton()">查询</el-button>
            </el-col>
            <el-col :md="6" :sm="8"> </el-col>
          </el-row>
        </el-form>
      </div>
      <!--     列表区域   -->
      <el-table :data="dataColumnList">
        <el-table-column label="序号" type="index" align="center" />
        <el-table-column label="唯一标识" align="center" prop="uniqueId" />
        <el-table-column label="流程code" align="center" prop="reimburseCode" />
        <el-table-column label="项目名称" align="center" prop="projectName" />
        <el-table-column label="审核意见" align="center" prop="opinion" />
        <el-table-column label="关闭时间" align="center" prop="closeTime" />
        <el-table-column label="流程状态" align="center" prop="status" :formatter="reviewStatusFormat"/>

        
        <!-- <el-table-column label="审核人名称" align="center" prop="promoterName" /> -->

      </el-table>
      <pagination
        :total="queryParams.total"
        :page-size.sync="queryParams.pageSize"
        :current-page.sync="queryParams.currentPage"
      />
    </el-dialog>
  </div>
</template>
  
  
  <script>
import { processReviewListApi } from "@/api/work/project/reimburse";
export default {
  data() {
    return {
      loading: false,
      title: "查看记录",
      dialog: false,
      // 选中数组
      questionnaireId: "",
      radio: null,
      ids: null,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      total: 0,
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      optFrom: {},
      dataColumnList: [],
      types: 1,
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.selectDataListButton();
    },
    "queryParams.pageSize"(val) {
      this.selectDataListButton();
    },
  },
  created() {
    this.selectDataListButton();
  },
  methods: {
    show(row, type) {
      this.types = type;
      this.dialog = true;
      this.editShow = true;
      this.title = "编辑";
      this.queryParams.reimburseCode = row.code;
      this.selectDataListButton();
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then((_) => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch((_) => {});
    },
    selectDataListButton() {
      processReviewListApi(this.queryParams).then((res) => {
        this.dataColumnList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },

    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.dataColumnList = [];
      this.editShow = false;
      this.$parent.selectDataListButton();
    },
    reviewStatusFormat(row, column) {
      if (row.status == 1) {
        return "草稿";
      }
      if (row.status == 2||row.status == 6) {
        return "流程中";
      }
      if (row.status == 3) {
        return "退回";
      }
      if (row.status == 4) {
        return "拒绝";
      }
      if (row.status == 5) {
        return "以接受";
      }
      if (row.status == 7) {
        return "流程结束";
      }
    },
  },
};
</script>
  
  
  
  