<template>
  <div class="app-container">
    <!-- 查询区域 -->
    <div>
      <el-form layout="inline">
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-button @click="selectDataListButton()">查询</el-button>
          </el-col>
          <el-col :md="6" :sm="8"> </el-col>
        </el-row>
      </el-form>
    </div>
    <!--     列表区域   -->
    <el-table :data="dataColumnList" @row-dblclick="dataBackFillGoods">
      <el-table-column label="序号" align="center" type="index" width="65" />
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <!-- <el-button
            type="text"
            icon="el-icon-edit"
            size="min"
            @click="editButton(scope.row)"
            >编辑</el-button
          > -->
          <el-button
            type="text"
            icon="el-icon-edit"
            size="min"
            @click="reimburesLogButton(scope.row)"
            >查看流程</el-button
          >
          <el-button
            type="text"
            icon="el-icon-edit"
            size="min"
            v-if="scope.row.reviewStatus != '5'"
            @click="selectByCode(scope.row)"
            >处理</el-button
          >
          &nbsp;
          <!-- <el-popover placement="top" width="160" v-model="visible"  v-if="scope.row.reviewStatus == 5">
            <p>流程将关闭</p>
            <div style="text-align: right; margin: 10px 0px">
              <el-button size="mini" type="text" @click="visible = false"
                >取消</el-button
              >
              <el-button type="primary" size="mini"  @click="closeButton(scope.row)"
                >确定</el-button
              >
            </div>
            <el-button slot="reference"  icon="el-icon-edit" type="text">关闭</el-button>
          </el-popover> -->
          <el-button slot="reference" type="text"     @click="closeButton(scope.row)">关闭</el-button>
        </template>
      </el-table-column>
      <el-table-column label="流程编码" prop="code" />
      <el-table-column label="项目名称" prop="projectName" />
      <el-table-column label="提交人名称" prop="promoterName" />
      <el-table-column label="单据开始时间" prop="startTime" />
      <el-table-column label="单据结束时间" prop="endTime" />
      <el-table-column label="提交时间" prop="createdTime" />
      <el-table-column
        label="审核状态"
        prop="reviewStatus"
        :formatter="reviewStatusFormat"
      />
    </el-table>

    <pagination
      :total="queryParams.total"
      :page-size.sync="queryParams.pageSize"
      :current-page.sync="queryParams.currentPage"
    />
    <reimburesLog ref="reimburesLogFrom" />
    <addEditreimburse ref="addEditreimburseForm" />
  </div>
</template>
  
  
  <script>
import reimburesLog from "../reimburse/modules/reimburesLog";
import addEditreimburse from "../reimburse/modules/addEditreimburse";
import {
  adminListReimburseApi,
  processReviewCloseApi,
} from "@/api/work/project/reimburse";
export default {
  components: {
    addEditreimburse,
    reimburesLog,
  },
  data() {
    return {
      visible: false,
      // 选中数组
      radio: null,
      ids: null,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      total: 0,
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      optFrom: {},
      dataColumnList: [],
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.selectDataListButton();
    },
    "queryParams.pageSize"(val) {
      this.selectDataListButton();
    },
  },
  created() {
    this.selectDataListButton();
  },
  methods: {
    selectDataListButton() {
      adminListReimburseApi(this.queryParams).then((res) => {
        this.dataColumnList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    reimburesLogButton(row) {
      if (row.code == null) return this.$message.error("请选择有效的一列");
      this.$refs.reimburesLogFrom.show(row, 2);
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.ids = row.id;
      this.optFrom = row;
    },
    primaryRest() {
      this.$refs["queryParams"].resetFields();
      this.selectDataListButton();
    },
    //这里是双击事件
    dataBackFillGoods(row, event, column) {
      console.log("双击事件", row, event, column);
      // this.$refs.addEditFrom.show(row.code, 3);
    },
    reviewStatusFormat(row, column) {
      if (row.reviewStatus == 2 || row.reviewStatus == 6) {
        return "待审核";
      }
      if (row.reviewStatus == 5) {
        return "以确认";
      }
      if (row.reviewStatus == 2) {
        return "否";
      }
    },

    selectByCode(row) {
      this.$refs.addEditreimburseForm.show(row.code, 4);
    },
    closeButton(row) {
      processReviewCloseApi({ code: row.code }).then((res) => {
        this.visible = false
        this.selectDataListButton();
      });
    },
  },
};
</script>
  
  
  
  